import React from "react"
import PropTypes from "prop-types"
import ExcluPage from "../containers/excluPage"
import doubloMLgif from "../images/doublo-ML.gif"

const CadreEntreDeuxVerres = (props) => {
  return (
    <>
      <ExcluPage
        title="Cadre doublo marie louise"
        description={
          <>
            Ce cadre doublo marie louise permet de donner une touche classique et élégante au sujet encadré.
            <br />
            Spécialement conçu pour encadrer vos toiles sur châssis ou vos cartons toilés, pourra aussi convenir pour encadrer vos photos, aquarelles,
            dessins...
            <br />
            La couleur de la marie louise intérieure est proposée dans 3 finitions : de couleur crème avec filet or, crème filet argent ou complètement crème.
          </>
        }
        link="/cadres/Cadre-doublo-marie-louise/"
        img={doubloMLgif}
        location={props.location}
      />
    </>
  )
}

CadreEntreDeuxVerres.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadreEntreDeuxVerres
